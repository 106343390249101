import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { AnimatePresence, motion } from 'framer-motion';
import { Tooltip } from '@mui/material';
import './styles/home.scss'
import ReactGA from "react-ga4";
import Marquee from "react-fast-marquee";

import styled, { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme, GlobalStyles } from '../themes.js'

import BackToTopButton from '../component/back-to-top.js';

import logo from '../assets/img/miravite.PNG'
import logo60 from '../assets/img/mai-60.svg'
import banner1 from '../assets/img/banner1.png'
import feliciano from '../assets/img/feliciano.png'
import faye from '../assets/img/faye.png'
import romeo from '../assets/img/romeo.png'
import careers from '../assets/img/careers.png'
import lobPension from '../assets/img/lob-pension.png'
import lobEmp from '../assets/img/lob-emp.png'
import lobInsu from '../assets/img/lob-insu.png'
import rma from '../assets/img/rma.png'
import recom from '../assets/img/recom.png'
import romeo2 from '../assets/img/romeo-02.png'
import iso from '../assets/img/iso.png'
import ifrs17 from '../assets/img/ifrs17.png'
import upcoming from '../assets/img/upcoming.png'
import pas2024 from '../assets/img/pas2024.png'


// Initialize ReactGA with your Measurement ID
ReactGA.initialize('G-LLD6SHTYGK');

function Home(themeData) {

    useEffect(() => {
      document.title = 'Miravite Consulting Group';
    }, []);

    // function to navigate to path
    const history = useHistory();

    return (
        <ThemeProvider theme={themeData.theme === 'light' ? lightTheme : darkTheme}>
        <GlobalStyles />


        <div id="home">

        <div className="parent">
          
          <div className="div1 mcg-logo">
            <img src={logo} />
          </div>
          <div className="div2 banner">
            <div className='inner-banner'>
              
              <div className="image-slider">
                <div className="zoom-container">
                  <img src={banner1} alt="Zooming Image" />
                </div>
              </div>

              <div className='banner-overlay'></div>

              {/* <span className='banner-title'>
              &nbsp;The first and largest actuarial consulting firm in the Philippines.
              </span> */}

              <div className='banner-title'>
                {/* <Marquee> */}
                <span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  The first and largest actuarial consulting firm in the Philippines.</span>
                {/* </Marquee> */}
              </div>

            </div>
          </div>
          <div className="div3 contact-us">
            <div className='inner-contact'>
              <div className='contact-title'>
                <span>Contact Us</span>
              </div>
              <div className='contact-icons'>

                <a href={`tel:+639176260572`}>
                  <div className='each-contact-details'>
                    <div className='each-contact-icon call'>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_210_167)">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.862 16.6886C18.2817 18.4521 16.0082 19.3315 14.3838 19.1849C12.1654 18.9843 9.75152 17.8077 7.91915 16.5118C5.22573 14.6067 2.70277 11.6598 1.23261 8.53277C0.193595 6.32314 -0.0395224 3.60642 1.50584 1.59232C2.07736 0.847837 2.69525 0.450226 3.62397 0.403853C4.9124 0.341186 5.09288 1.07827 5.5353 2.22633C5.86493 3.08486 6.30485 3.96063 6.5505 4.85049C7.01048 6.51116 5.40245 6.58057 5.19941 7.93793C5.07282 8.79395 6.11058 9.94193 6.57933 10.5523C7.49802 11.748 8.60221 12.7793 9.84802 13.5639C10.5612 14.0138 11.713 14.8246 12.5302 14.3772C13.7885 13.6878 13.6694 11.566 15.4291 12.2842C16.3403 12.6552 17.2226 13.1906 18.0924 13.6593C19.4373 14.3825 19.3746 15.132 18.862 16.6886C19.2455 15.5255 18.4785 17.8517 18.862 16.6886Z" fill="white" />
                        </g>
                        <defs>
                          <clipPath id="clip0_210_167">
                            <rect width="18.8" height="18.8" fill="white" transform="translate(0.400024 0.400146)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <span className='each-contact-info'>+63 917 626 0572</span>
                  </div>
                </a>

                <a href="mailto:info@MAI-Asia.com">
                  <div className='each-contact-details'>
                    <div className='each-contact-icon email'>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_210_174)">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M9.93332 14.4758L0.533325 6.18029V16.8502H19.3333V6.18029L9.93332 14.4758ZM9.93427 11.9735L0.533325 3.6724V2.75024H19.3333V3.6724L9.93427 11.9735Z" fill="white" />
                        </g>
                        <defs>
                          <clipPath id="clip0_210_174">
                            <rect width="18.8" height="18.8" fill="white" transform="translate(0.533325 0.400146)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <span className='each-contact-info'>info@MAi-Asia.com</span>
                  </div>
                </a>

                <a href="https://www.facebook.com/MiraviteConsulting/" target="_blank">
                  <div className='each-contact-details'>
                    <div className='each-contact-icon facebook'>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_210_181)">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7786 19.2001V10.7401H14.347L14.7666 6.98015H11.7786V5.1488C11.7786 4.1806 11.8033 3.22015 13.1563 3.22015H14.5266V0.531875C14.5266 0.491455 13.3495 0.400146 12.1587 0.400146C9.67177 0.400146 8.11457 1.95791 8.11457 4.81833V6.98015H5.36658V10.7401H8.11457V19.2001H11.7786Z" fill="white" />
                        </g>
                        <defs>
                          <clipPath id="clip0_210_181">
                            <rect width="18.8" height="18.8" fill="white" transform="translate(0.666626 0.400146)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <span className='each-contact-info'>Miravite Consulting Group</span>
                  </div>
                </a>
                
              </div>
              <div></div>
            </div>
          </div>
          <div className="widget div4 about-us">
            <div className='inner-about'>
              <div className='about-title'>
                <span>Our Leadership History</span>
              </div>
              <div className='about-album'>
                <div className='each-album'>
                  <img src={feliciano} />
                  <span className='full-name'>Feliciano F. Miravite</span>
                  <span className='suffix'>FASP</span>
                  <span className='position'>Founder</span>
                </div>
                <div className='each-album'>
                  <img src={faye} />
                  <span className='full-name'>Faye S. Miravite</span>
                  <span className='suffix'>FSA</span>
                  <span className='position'>Ex-Officio <br/> Chairman</span>
                </div>
                <div className='each-album'>
                  <img src={romeo} />
                  <span className='full-name'>Romeo H. Abada</span>
                  <span className='suffix'>FSA FASP FLMI</span>
                  <span className='position'>CEO & Senior <br/> Consulting Actuary</span>
                </div>
              </div>
              <div className='about-button' onClick={() => history.push('/about')}>
                <button className="button">
                  <span>About the Miravite Story</span>
                  <span>
                    <svg height="15" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className='arrow-right' d="M14.8203 17.8463L13.6408 16.6476L20.4549 9.83339H0.333252V8.16673H20.4549L13.6408 1.35256L14.8203 0.153809L23.6666 9.00006L14.8203 17.8463Z" />
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="div5 lob-service">
            <div className='lob-img'>
              <img src={lobPension} />
            </div>
            <div className='lob-overlay'></div>
            <div className='inner-pension'>
              <div className='pension-title'>
                <span>Pension Actuarial <br /> Valuation</span>
              </div>
              <div className='pension-tags'>
                <div className='tag-holder'>
                  <span>IAS 19 / PAS 19 </span>
                </div>
                <div className='tag-holder'>
                  <span>PFRS for SME / SE </span>
                </div>
                <div className='tag-holder'>
                  <span>Funding Valuation </span>
                </div>
              </div>
              <div className='pension-button'>
                <button className="button" onClick={() => history.push('/pension-actuarial-valuation')}>
                  <span>Learn More</span>
                  <span>
                    <svg height="15" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className='arrow-right' d="M14.8203 17.8463L13.6408 16.6476L20.4549 9.83339H0.333252V8.16673H20.4549L13.6408 1.35256L14.8203 0.153809L23.6666 9.00006L14.8203 17.8463Z" />
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="div6 lob-service">
            <div className='lob-img'>
              <img src={lobEmp} />
            </div>
            <div className='lob-overlay-emp'></div>
            <div className='inner-pension'>
              <div className='pension-title'>
                <span>Employee Benefits <br /> Administration</span>
              </div>
              <div className='bene-tags'>
                <div className='tag-holder'>
                  <span>Online Retirement Portal </span>
                </div>
                <div className='tag-holder'>
                  <span>DC Plan Ledgering </span>
                </div>
                <div className='tag-holder'>
                  <span>DB2DC Transition </span>
                </div>
              </div>
              <div className='bene-button'>
                <button className="button" onClick={() => history.push('/employee-benefits-administration')}>
                  <span>Learn More</span>
                  <span>
                    <svg height="15" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className='arrow-right' d="M14.8203 17.8463L13.6408 16.6476L20.4549 9.83339H0.333252V8.16673H20.4549L13.6408 1.35256L14.8203 0.153809L23.6666 9.00006L14.8203 17.8463Z" />
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="div7 lob-service">
            <div className='lob-img'>
              <img src={lobInsu} />
            </div>
            <div className='lob-overlay-insu'></div>
            <div className='inner-pension'>
              <div className='pension-title'>
                <span>Insurance Management <br /> Consulting</span>
              </div>
              <div className='insu-tags'>
                <div className='tag-holder'>
                  <span>IFRS17 </span>
                </div>
                <div className='tag-holder'>
                  <span>Actuarial Certification </span>
                </div>
                <div className='tag-holder'>
                  <span>Mergers & Acquisition </span>
                </div>
              </div>
              <div className='insu-button'>
                <button className="button" onClick={() => history.push('/insurance-management-consulting')}>
                  <span>Learn More</span>
                  <span>
                    <svg height="15" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className='arrow-right' d="M14.8203 17.8463L13.6408 16.6476L20.4549 9.83339H0.333252V8.16673H20.4549L13.6408 1.35256L14.8203 0.153809L23.6666 9.00006L14.8203 17.8463Z" />
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="widget div8 quote-meet" onClick={() => history.push('/contact')}>
            <span>
              Request for <br /> Quote/Meet
            </span>
          </div>
          <div className="widget div9 careers" onClick={() => history.push('/careers')}>
            <span className='careers-title'>Careers</span>
            <img src={careers} alt="group photo" />
          </div>
          <div className="widget div10 youtube">
            <iframe height="100%" width="100%" src="https://www.youtube.com/embed/ZI3nk6NNJ_A?si=clObRQLoxhFKxeBs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div className="div11 news-pension">
            <div className='top'>
              <div className='news-tag'>
                <span>Latest Release</span>
              </div>
            </div>
            <div className='center'>
              <img className='recom' src={recom} />
              <span className='rma-subtitle'>The Miravite <br /> Actuary</span>
              <img className='rma' src={rma} />
              <img className='romeo-blue' src={romeo2} />
            </div>
            <div className='bot'>
            <HashLink 
            smooth to="/pension-actuarial-valuation#related-article"
            style={{ textDecoration: 'none', color: 'inherit' }} >
            <div className='news-pension-button'>
              <button className="button">
                <span>Browse More</span>
                <span>
                  <svg height="15" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path className='arrow-right' d="M14.8203 17.8463L13.6408 16.6476L20.4549 9.83339H0.333252V8.16673H20.4549L13.6408 1.35256L14.8203 0.153809L23.6666 9.00006L14.8203 17.8463Z" />
                  </svg>
                </span>
              </button>
            </div>
            </HashLink>
            </div>
          </div>
          <div className="div12 news-benefits">
            <div className='top'>
              <div className='news-tag'>
                <span>What's New?</span>
              </div>
            </div>
            <div className='center'>
              <a href='https://www.mai-asia.com/read/benefits-article/19-from-basic-concepts-to-optimizing-tax-exemptions'>
              <img className='iso' src={pas2024} />
              </a>
            </div>
            <span className='news-headline'>
                <a href='https://forms.office.com/r/P2nFDs4WSN'>
                <p><strong>Click here to Register</strong></p>
                </a>
              </span>
            <div className='bot'>
            <HashLink 
            smooth to="/employee-benefits-administration#related-article"
            style={{ textDecoration: 'none', color: 'inherit' }} >
            <div className='news-pension-button'>
              <button className="button">
                <span>Browse More</span>
                <span>
                  <svg height="15" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path className='arrow-right' d="M14.8203 17.8463L13.6408 16.6476L20.4549 9.83339H0.333252V8.16673H20.4549L13.6408 1.35256L14.8203 0.153809L23.6666 9.00006L14.8203 17.8463Z" />
                  </svg>
                </span>
              </button>
            </div>
            </HashLink>
            </div>
          </div>
          <div className="div13 news-insurance">
            <div className='top'>
              <div className='news-tag'>
                <span>Upcoming Event</span>
              </div>
            </div>
            <div className='center'>
              <img className='iso' src={ifrs17} />
              <a href='https://www.mai-asia.com/read/insurance-article/the-new-countdown-to-17-implementation-convergence-of-the-upcoming-reporting-standards-and-the-current-reporting-on-quantitative-impact-assessment'>
              <img className='iso' src={upcoming} />
              </a>
             
            </div>
            <span className='news-headline'>
                <a href='https://forms.office.com/r/D6Biqpgkjz'>
                <p><strong>Click here to Register</strong></p>
                </a>
              </span>
            <div className='bot'>
            <HashLink 
            smooth to="/insurance-management-consulting#related-article"
            style={{ textDecoration: 'none', color: 'inherit' }} >
            <div className='news-pension-button'>
              <button className="button">
                <span>Browse More</span>
                <span>
                  <svg height="15" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path className='arrow-right' d="M14.8203 17.8463L13.6408 16.6476L20.4549 9.83339H0.333252V8.16673H20.4549L13.6408 1.35256L14.8203 0.153809L23.6666 9.00006L14.8203 17.8463Z" />
                  </svg>
                </span>
              </button>
            </div>
            </HashLink>
            </div>
          </div>
          <div className="div14 feedback">
            <div className='inner-feedback'>
              <div className='feedback-title'>
                <span>Feedback</span>
              </div>
              <div className='feedback-tags'>
                <span>A Message to the CEO: <br/> Your voice matters, and we're eager to hear from you!</span>
              </div>
              <div className='feedback-button'>
                <a href="mailto:romeo.abada@mai-asia.com?subject=Message%20To%20The%20CEO">
                <button className="button">
                  <span>Send Email</span>
                  <span>
                    <svg height="15" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className='arrow-right' d="M14.8203 17.8463L13.6408 16.6476L20.4549 9.83339H0.333252V8.16673H20.4549L13.6408 1.35256L14.8203 0.153809L23.6666 9.00006L14.8203 17.8463Z" />
                    </svg>
                  </span>
                </button>
                </a>
              </div>
            </div>
          </div>
          <div className="widget div15 quote">
            <div className='quote-left'>
              <img src={logo60} alt="60th Anniversary Logo" />
              <div className='company'>
                <span>Miravite Consulting Group</span>
                <span>Forging Excellence 60 Years & Beyond.</span>

              </div>
            </div>
            <div className='quote-right'>
              <span>Actuarial • Finance • Employee Benefits</span>
            </div>
          </div>
        </div>


        </div>
        </ThemeProvider>
    )
}

export default Home;