import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme, GlobalStyles } from '../themes.js'
import { Link } from 'react-router-dom';
import './styles/benefits.scss'
import Marquee from "react-fast-marquee";
import lobPension from '../assets/img/lob-pension.png'
import lobBenefits from '../assets/img/lob-emp.png'
import banner from '../assets/img/banner3.jpg'
import portal from '../assets/img/portal.png'
import BackToTopButton from '../component/back-to-top.js';
import emailjs from 'emailjs-com';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Alert from '@mui/material/Alert';

import retirement from '../assets/img/online-retirement-portal.png'
import trust from '../assets/img/trust-management.png'
import design from '../assets/img/plan-design.png'
import governance from '../assets/img/plan-governance.png'

import delmonte from '../assets/img/partners/delmonte.png'
import infor from '../assets/img/partners/infor.png'
import ihg from '../assets/img/partners/ihg.png'
import nestle from '../assets/img/partners/nestle.png'
import coca from '../assets/img/partners/coca.png'
import mcdo from '../assets/img/partners/mcdo.png'

import articleBenefits from '../data/articleBenefits.json'


function Benefits(themeData) {

  useEffect(() => {
    document.title = 'Employee Benefits Administration • Miravite Consulting Group';
  }, []);

  // Function to convert headline to kebab case
  const toKebabCase = (str) =>
    str &&
    str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|[A-Z]*$)|[A-Z]?[a-z]+[0-9]*|[0-9]+/g)
      .map((x) => x.toLowerCase())
      .join('-');

  const [showAllBenefitsRelatedArticles, setShowAllBenefitsRelatedArticles] = useState(false);

  // Function to check if the stored timestamp is within the last 10 minutes
  const isTimestampValid = (timestamp) => {
    const tenMinutes = 10 * 60 * 1000; // 10 minutes in milliseconds
    const currentTime = new Date().getTime();
    // console.log(`Current Time: ${currentTime}, Saved Time: ${timestamp}`);
    // console.log(`Difference: ${currentTime - timestamp}, Valid: ${currentTime - timestamp < tenMinutes}`);
    return currentTime - timestamp < tenMinutes;
  };

  // Initialize state from local storage
  useEffect(() => {
    const checkDataValidity = () => {
      const savedData = localStorage.getItem('showAllBenefitsRelatedArticlesData');
      if (savedData) {
        const parsedData = JSON.parse(savedData);
        if (isTimestampValid(parsedData.timestamp)) {
          setShowAllBenefitsRelatedArticles(parsedData.showAllBenefitsRelatedArticles);
        } else {
          // Data is expired, remove from local storage
          localStorage.removeItem('showAllBenefitsRelatedArticlesData');
          setShowAllBenefitsRelatedArticles(false);
        }
      }
    };
  
    checkDataValidity(); // Initial check on component mount
  
    const interval = setInterval(checkDataValidity, 60000); // Check every 1 minute
  
    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  // Update local storage whenever 'showAllBenefitsRelatedArticles' changes
  useEffect(() => {
    if (showAllBenefitsRelatedArticles) {
      const dataToStore = {
        showAllBenefitsRelatedArticles: showAllBenefitsRelatedArticles,
        timestamp: new Date().getTime(),
      };
      localStorage.setItem('showAllBenefitsRelatedArticlesData', JSON.stringify(dataToStore));
    } else {
      localStorage.removeItem('showAllBenefitsRelatedArticlesData');
    }
  }, [showAllBenefitsRelatedArticles]);

  const [formData, setFormData] = useState({
    lineOfBusiness: 'Employee Benefits Administration',
    addresseeName: '',
    addresseeDesignation: '',
    companyName: '',
    companyAddress: '',
    employeeCount: '',
    fiscalYearEnd: '',
    actuarialProvider: '',
    retirementPlanType: '',
    contactNumber: '',
    emailAddress: '',
    message: '',
    dcAdmin: '',
    onlineManual: '',
    isPensionForm: 'yes'
  });

  const [resultMessage, setResultMessage] = useState('');
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  
    // Remove the error key for the field
    if (errors[name]) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault();

     // Initialize an empty errors object
    let tempErrors = {};

    // Validate each required field individually

    if (!formData.addresseeName) {
      tempErrors.addresseeName = 'This field is required.';
    }

    // if (!formData.addresseeDesignation) {
    //   tempErrors.addresseeDesignation = 'This field is required.';
    // }

    if (!formData.companyName) {
      tempErrors.companyName = 'This field is required.';
    }

    // if (!formData.companyAddress) {
    //   tempErrors.companyAddress = 'This field is required.';
    // }

    if (!formData.employeeCount) {
      tempErrors.employeeCount = 'This field is required.';
    }

    // if (!formData.fiscalYearEnd) {
    //   tempErrors.fiscalYearEnd = 'This field is required.';
    // }

    // if (!formData.actuarialProvider) {
    //   tempErrors.actuarialProvider = 'This field is required.';
    // }

    // if (!formData.retirementPlanType) {
    //   tempErrors.retirementPlanType = 'This field is required.';
    // }

    // if (!formData.contactNumber) {
    //   tempErrors.contactNumber = 'This field is required.';
    // }

    if (!formData.emailAddress) {
      tempErrors.emailAddress = 'This field is required.';
    }

    // if (!formData.message) {
    //   tempErrors.message = 'This field is required.';
    // }

    // if (!formData.dcAdmin) {
    //   tempErrors.dcAdmin = 'This field is required.';
    // }

    if (!formData.onlineManual) {
      tempErrors.onlineManual = 'This field is required.';
    }

    // Update the errors state
    setErrors(tempErrors);

    // If there are errors, do not proceed
    if (Object.keys(tempErrors).length > 0) {
      return;
    }

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      // e.target, 
      formData,
      process.env.REACT_APP_EMAILJS_USER_ID
    )
    .then((response) => {
      // console.log('SUCCESS!', response.status, response.text);
      setResultMessage('Your message has been sent successfully!');
      setSuccess(true); 
      setFormData({
        lineOfBusiness: 'Employee Benefits Administration',
        addresseeName: '',
        addresseeDesignation: '',
        companyName: '',
        companyAddress: '',
        employeeCount: '',
        fiscalYearEnd: '',
        actuarialProvider: '',
        retirementPlanType: '',
        contactNumber: '',
        emailAddress: '',
        message: '',
        dcAdmin: '',
        onlineManual: '',
      });
      setErrors({}); // Clear errors after successful submission
      // console.log('FORM DATAAAAAAA',formData)
    })
    .catch((err) => {
      console.error('FAILED...', err);
      setResultMessage('An error occurred. Please try again.');
    });
  };

  const [success, setSuccess] = useState(false);

  return (

    <ThemeProvider theme={themeData.theme === 'light' ? lightTheme : darkTheme}>
    <GlobalStyles />
    <BackToTopButton />


      <div id='benefits'>

        <header>
          <div className='banner-img'>
            <img src={lobBenefits} />
          </div>
          <div className='banner-overlay'>
            <div className='banner-wrapper'>
              <div className='banner-title'>Employee Benefits Administration</div>
              <div className='banner-subtitle'>The new employee experience in retirement benefits.</div>
            </div>
          </div>
        </header>

        <section className="benefits">
            <div className='benefits-container'>
              <span className='benefits-title'>The Miravite Online Portal</span>

              <div className='portal-container'>
                <div className='portal-img'>
                  <img src={portal} />
                  
                </div>
                <div className='portal-article'>
                  {/* <span className='portal-title'>Lorem Ipsum</span> */}
                  {/* <br />
                  <br /> */}
                  <span>Introduced in 2012, the Miravite Online Portal is the first online retirement member statement in the Philippines. We have upgraded our in-house developed system to include a Dashboard, Enrollment Facility, and Export Tools (PDF and Excel) for the Admin Users. We continue to remain at the forefront of innovations in the Retirement space for maximum member engagement and overall employee experience.</span>
                </div>
              </div>

              <span className='benefits-title'>Retirement Consulting Services</span>

              <div className='retirement-container'>

                <div className='each-retirement-card'>
                  <div className='ret-img'>
                    <img src={retirement} />
                  </div>
                  <span className='ret-title'>Online Retirement Portal</span>
                  <span className='ret-desc'>DC Plan Ledgering, Digital Statement of Account, Enrollment Tool </span>
                </div>
                <div className='each-retirement-card'>
                  <div className='ret-img'>
                    <img src={trust} />
                  </div>
                  <span className='ret-title'>Retirement Plan Design / Re-Design </span>
                  <span className='ret-desc'>Plan Rules & Regulations, Benchmarking & Cost Forecasting, BIR Filing </span>
                </div>
                <div className='each-retirement-card'>
                  <div className='ret-img'>
                    <img src={design} />
                  </div>
                  <span className='ret-title'>Trust Set-Up</span>
                  <span className='ret-desc'>Fund Manager Selection, Investment Management Agreement Advisory </span>
                </div>
                <div className='each-retirement-card'>
                  <div className='ret-img'>
                    <img src={governance} />
                  </div>
                  <span className='ret-title'>Retirement Plan Governance</span>
                  <span className='ret-desc'>Retirement Committee Advisory, Governance Policy Framework </span>
                </div>
              </div>

              {/* <span className='benefits-title'>Business Partners</span>
              <span className='benefits-subtitle'>As the largest and leading provider of DC Administration Services in the Philippines, our portfolio continuous to grow with now over 130 clients covering 120,000+ employees from various industries.</span>

              <div className='partners-container'>
                <Marquee pauseOnHover={true}>
                  <div className='partners-img-holder'>
                    <img src={delmonte} />
                  </div>
                  <div className='partners-img-holder'>
                    <img src={infor} />
                  </div>
                  <div className='partners-img-holder'>
                    <img src={ihg} />
                  </div>
                  <div className='partners-img-holder'>
                    <img src={nestle} />
                  </div>
                  <div className='partners-img-holder'>
                    <img src={coca} />
                  </div>
                  <div className='partners-img-holder'>
                    <img src={mcdo} />
                  </div>
                </Marquee>
              </div> */}

              <div className='proposal-container'>
                <div className='prop-title'>
                  <span className='proposal'>Request for Meeting</span>
                </div>
                <div className='prop-body'>

                <form onSubmit={handleSubmit}>
                      <div className='two-cols'>
                        <TextField
                          className='each-input'
                          id="outlined-basic"
                          label="Name"
                          variant="outlined"
                          value={formData.addresseeName}
                          onChange={handleChange} name="addresseeName"
                          error={!!errors.addresseeName}
                          helperText={errors.addresseeName} />
                        <TextField
                          className='each-input'
                          id="outlined-basic"
                          label="Designation"
                          variant="outlined"
                          value={formData.addresseeDesignation}
                          onChange={handleChange}
                          name="addresseeDesignation"
                          error={!!errors.addresseeDesignation}
                          helperText={errors.addresseeDesignation} />
                      </div>
                      <div className='two-cols'>
                        <TextField
                          className='each-input'
                          id="outlined-basic"
                          label="Company Name"
                          variant="outlined"
                          value={formData.companyName}
                          onChange={handleChange}
                          name="companyName"
                          error={!!errors.companyName} helperText={errors.companyName} />
                        <TextField
                          className='each-input'
                          id="outlined-basic"
                          label="Company Address"
                          variant="outlined"
                          value={formData.companyAddress}
                          onChange={handleChange}
                          name="companyAddress"
                          error={!!errors.companyAddress}
                          helperText={errors.companyAddress} />
                      </div>
                      <div className='two-cols'>
                        <TextField
                          className='each-input'
                          id="outlined-basic"
                          label="Headcount"
                          variant="outlined"
                          value={formData.employeeCount}
                          onChange={handleChange}
                          name="employeeCount"
                          error={!!errors.employeeCount}
                          helperText={errors.employeeCount} />
                        <TextField className='each-input'
                          id="outlined-basic"
                          label="Current DC Administrator"
                          variant="outlined"
                          value={formData.dcAdmin}
                          onChange={handleChange}
                          name="dcAdmin"
                          error={!!errors.dcAdmin}
                          helperText={errors.dcAdmin} />
                      </div>
                      <div className='two-cols'>
                        <TextField
                          className='each-input'
                          id="outlined-basic"
                          label="Email Address"
                          variant="outlined"
                          value={formData.emailAddress}
                          onChange={handleChange}
                          name="emailAddress"
                          error={!!errors.emailAddress}
                          helperText={errors.emailAddress} />
                        <TextField
                          className='each-input'
                          id="outlined-basic"
                          label="Contact Number"
                          variant="outlined"
                          value={formData.contactNumber}
                          onChange={handleChange}
                          name="contactNumber"
                          error={!!errors.contactNumber}
                          helperText={errors.contactNumber} />
                      </div>
                      <div className='two-cols'>
                        <TextField
                          className='each-input'
                          id="outlined-basic"
                          label="Online or Paper-Based"
                          variant="outlined"
                          value={formData.onlineManual}
                          onChange={handleChange}
                          name="onlineManual"
                          error={!!errors.onlineManual}
                          helperText={errors.onlineManual} />
     
                      </div>
                      <div className='message'>
                        <TextField
                          multiline
                          rows={4}
                          className='each-input'
                          id="outlined-basic"
                          label="Message"
                          ariant="outlined"
                          value={formData.message}
                          onChange={handleChange}
                          name="message"
                          error={!!errors.message}
                          helperText={errors.message} />
                      </div>
                      <div className='alert'>
                        {success && (
                          <Alert severity="success" style={{ marginTop: '1em' }}>
                            Your message has been sent successfully!
                          </Alert>
                        )}
                      </div>

                      <div className='two-cols'>
                        <div className='blank'></div>
                        <button className='send-form' type='submit'> Submit </button>
                      </div>
                    </form>
                </div>
              </div>

              <span id='related-article'></span>
              <div className='related-articles-container'>
                <span className='related-articles'>Related Articles</span>
              </div>

              <div className='articles-container'>
                {articleBenefits
                  .slice(0, showAllBenefitsRelatedArticles ? articleBenefits.length : 3)
                  .map((article, index) => (
                    <Link 
                      to={`/read/benefits-article/${toKebabCase(article.headline)}`}
                      style={{ textDecoration: 'none', color: 'inherit' }} 
                      key={index} // Add a unique key for each item
                    >
                      <div className='each-article'>
                        <div className='article-banner-container'>
                          <img src={`img/assets/benefits/${article.img}`} alt={article.headline} />
                        </div>
                        <div className='article-contents'>
                          <span className='article-date'>{article.date}</span>
                          <span className='article-headline'>{article.headline}</span>
                          <span className='article-body'>
                            {`${article.body[0]}`.substring(0, 250)}...
                          </span>
                        </div>
                      </div>
                    </Link>
                  ))}
              </div>
              {!showAllBenefitsRelatedArticles && (
                <div className='show-all-container'>
                  <span className='show-all' onClick={() => setShowAllBenefitsRelatedArticles(true)}>Show All</span>
                </div>
              )}


            </div>
        </section>
      </div>
    </ThemeProvider>
  );
};

export default Benefits;