import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme, GlobalStyles } from '../themes.js'
import './styles/contact.scss'
import BackToTopButton from '../component/back-to-top.js';

import lobPension from '../assets/img/lob-pension.png'
import lobEmp from '../assets/img/lob-emp.png'
import lobInsu from '../assets/img/lob-insu.png'

import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import emailjs from 'emailjs-com';

function Contact(themeData) {

  useEffect(() => {
    document.title = 'Contact • Miravite Consulting Group';
  }, []);

  const [selectedDiv, setSelectedDiv] = useState(null);

  const divData = [
    {
      id: 1,
      label: 'Pension Actuarial Valuation',
      image: lobPension,
      overlayColor: 'linear-gradient(45deg, rgba(252, 70, 106, 0.75) , rgba(63, 94, 251, 0.75))',
    },
    {
      id: 2,
      label: 'Employee Benefits Administration',
      image: lobEmp,
      overlayColor: 'linear-gradient(45deg, rgba(158, 189, 19, 0.75) , rgba(0, 133, 82, 0.75))',
    },
    {
      id: 3,
      label: 'Insurance Management Consulting',
      image: lobInsu,
      overlayColor: 'linear-gradient(45deg, rgba(0, 208, 255, 0.75) , rgba(58, 71, 213, 0.75))',
    },
  ];

  const handleClick = (divId) => {
    setSelectedDiv(divId);
    const selectedDivData = divData.find(div => div.id === divId);
    if (selectedDivData) {
      setFormData(prevFormData => ({
        ...prevFormData,
        lineOfBusiness: selectedDivData.label
      }));
    }
  };

  // form logic here
  const [formData, setFormData] = useState({
    lineOfBusiness: '',
    addresseeName: '',
    addresseeDesignation: '',
    companyName: '',
    companyAddress: '',
    employeeCount: '',
    fiscalYearEnd: '',
    actuarialProvider: '',
    retirementPlanType: '',
    contactNumber: '',
    emailAddress: '',
    message: '',
    dcAdmin: '',
    onlineManual: '',
    actuarialValuation: true, // Checked by default
    fundingValuation: true,    // Checked by default
    isPensionForm: '',
  });

  const [resultMessage, setResultMessage] = useState('');
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  
    // Remove the error key for the field
    if (errors[name]) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

     // Update isPensionForm to 'yes' on form submission
     const updatedFormData = {
      ...formData,
      isPensionForm: 'yes',
    };

    // Optionally update the state
    setFormData(updatedFormData);

     // Initialize an empty errors object
    let tempErrors = {};

    // Validate each required field individually

    if (!formData.addresseeName) {
      tempErrors.addresseeName = 'This field is required.';
    }

    // if (!formData.addresseeDesignation) {
    //   tempErrors.addresseeDesignation = 'This field is required.';
    // }

    if (!formData.companyName) {
      tempErrors.companyName = 'This field is required.';
    }

    // if (!formData.companyAddress) {
    //   tempErrors.companyAddress = 'This field is required.';
    // }

    if (!formData.employeeCount) {
      tempErrors.employeeCount = 'This field is required.';
    }

    // if (!formData.fiscalYearEnd) {
    //   tempErrors.fiscalYearEnd = 'This field is required.';
    // }

    // if (!formData.actuarialProvider) {
    //   tempErrors.actuarialProvider = 'This field is required.';
    // }

    if (!formData.retirementPlanType) {
      tempErrors.retirementPlanType = 'This field is required.';
    }

    // if (!formData.contactNumber) {
    //   tempErrors.contactNumber = 'This field is required.';
    // }

    if (!formData.emailAddress) {
      tempErrors.emailAddress = 'This field is required.';
    }

    // if (!formData.message) {
    //   tempErrors.message = 'This field is required.';
    // }

    // Update the errors state
    setErrors(tempErrors);

    // If there are errors, do not proceed
    if (Object.keys(tempErrors).length > 0) {
      return;
    }

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      // e.target, 
      updatedFormData,
      process.env.REACT_APP_EMAILJS_USER_ID
    )
    .then((response) => {
      // console.log('SUCCESS!', response.status, response.text);
      setResultMessage('Your message has been sent successfully!');
      setSuccess(true); 
      setFormData({
        lineOfBusiness: '',
        addresseeName: '',
        addresseeDesignation: '',
        companyName: '',
        companyAddress: '',
        employeeCount: '',
        fiscalYearEnd: '',
        actuarialProvider: '',
        retirementPlanType: '',
        contactNumber: '',
        emailAddress: '',
        message: '',
        isPensionForm: 'yes'
      });
      setErrors({}); // Clear errors after successful submission
      // console.log('FORM DATAAAAAAA',updatedFormData)
    })
    .catch((err) => {
      console.error('FAILED...', err);
      setResultMessage('An error occurred. Please try again.');
    });
  };
  const handleSubmitBenefits = (e) => {
    e.preventDefault();

     // Initialize an empty errors object
    let tempErrors = {};

    // Validate each required field individually

    if (!formData.addresseeName) {
      tempErrors.addresseeName = 'This field is required.';
    }

    // if (!formData.addresseeDesignation) {
    //   tempErrors.addresseeDesignation = 'This field is required.';
    // }

    if (!formData.companyName) {
      tempErrors.companyName = 'This field is required.';
    }

    // if (!formData.companyAddress) {
    //   tempErrors.companyAddress = 'This field is required.';
    // }

    if (!formData.employeeCount) {
      tempErrors.employeeCount = 'This field is required.';
    }

    // if (!formData.fiscalYearEnd) {
    //   tempErrors.fiscalYearEnd = 'This field is required.';
    // }

    // if (!formData.actuarialProvider) {
    //   tempErrors.actuarialProvider = 'This field is required.';
    // }

    // if (!formData.retirementPlanType) {
    //   tempErrors.retirementPlanType = 'This field is required.';
    // }

    // if (!formData.contactNumber) {
    //   tempErrors.contactNumber = 'This field is required.';
    // }

    if (!formData.emailAddress) {
      tempErrors.emailAddress = 'This field is required.';
    }

    // if (!formData.message) {
    //   tempErrors.message = 'This field is required.';
    // }

    // if (!formData.dcAdmin) {
    //   tempErrors.dcAdmin = 'This field is required.';
    // }

    if (!formData.onlineManual) {
      tempErrors.onlineManual = 'This field is required.';
    }

    // Update the errors state
    setErrors(tempErrors);

    // If there are errors, do not proceed
    if (Object.keys(tempErrors).length > 0) {
      return;
    }

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      // e.target, 
      formData,
      process.env.REACT_APP_EMAILJS_USER_ID
    )
    .then((response) => {
      // console.log('SUCCESS!', response.status, response.text);
      setResultMessage('Your message has been sent successfully!');
      setSuccess(true); 
      setFormData({
        lineOfBusiness: '',
        addresseeName: '',
        addresseeDesignation: '',
        companyName: '',
        companyAddress: '',
        employeeCount: '',
        fiscalYearEnd: '',
        actuarialProvider: '',
        retirementPlanType: '',
        contactNumber: '',
        emailAddress: '',
        message: '',
        dcAdmin: '',
        onlineManual: '',
        actuarialValuation: true, // Checked by default
        fundingValuation: true,    // Checked by default
      });
      setErrors({}); // Clear errors after successful submission
      // console.log('FORM DATAAAAAAA',formData)
    })
    .catch((err) => {
      console.error('FAILED...', err);
      setResultMessage('An error occurred. Please try again.');
    });
  };
  const handleSubmitInsurance = (e) => {
    e.preventDefault();

     // Initialize an empty errors object
    let tempErrors = {};

    // Validate each required field individually

    if (!formData.addresseeName) {
      tempErrors.addresseeName = 'This field is required.';
    }

    // if (!formData.addresseeDesignation) {
    //   tempErrors.addresseeDesignation = 'This field is required.';
    // }

    if (!formData.companyName) {
      tempErrors.companyName = 'This field is required.';
    }

    // if (!formData.companyAddress) {
    //   tempErrors.companyAddress = 'This field is required.';
    // }

    // if (!formData.employeeCount) {
    //   tempErrors.employeeCount = 'This field is required.';
    // }

    // if (!formData.fiscalYearEnd) {
    //   tempErrors.fiscalYearEnd = 'This field is required.';
    // }

    // if (!formData.actuarialProvider) {
    //   tempErrors.actuarialProvider = 'This field is required.';
    // }

    // if (!formData.retirementPlanType) {
    //   tempErrors.retirementPlanType = 'This field is required.';
    // }

    // if (!formData.contactNumber) {
    //   tempErrors.contactNumber = 'This field is required.';
    // }

    if (!formData.emailAddress) {
      tempErrors.emailAddress = 'This field is required.';
    }

    // if (!formData.message) {
    //   tempErrors.message = 'This field is required.';
    // }

    // Update the errors state
    setErrors(tempErrors);

    // If there are errors, do not proceed
    if (Object.keys(tempErrors).length > 0) {
      return;
    }

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      // e.target, 
      formData,
      process.env.REACT_APP_EMAILJS_USER_ID
    )
    .then((response) => {
      // console.log('SUCCESS!', response.status, response.text);
      setResultMessage('Your message has been sent successfully!');
      setSuccess(true); 
      setFormData({
        lineOfBusiness: '',
        addresseeName: '',
        addresseeDesignation: '',
        companyName: '',
        companyAddress: '',
        employeeCount: '',
        fiscalYearEnd: '',
        actuarialProvider: '',
        retirementPlanType: '',
        contactNumber: '',
        emailAddress: '',
        message: '',
        actuarialValuation: true, // Checked by default
        fundingValuation: true,    // Checked by default
      });
      setErrors({}); // Clear errors after successful submission
      // console.log('FORM DATAAAAAAA',formData)
    })
    .catch((err) => {
      console.error('FAILED...', err);
      setResultMessage('An error occurred. Please try again.');
    });
  };

  const [success, setSuccess] = useState(false);

  const apiKey = process.env.REACT_APP_GOOGLE_MAP;
  const mapSrc = `https://www.google.com/maps/embed/v1/view?zoom=17&center=14.5471%2C121.0505&key=${apiKey}`;

  return (

    <ThemeProvider theme={themeData.theme === 'light' ? lightTheme : darkTheme}>
    <GlobalStyles />
    <BackToTopButton />


      <div id='contact'>

        <section className="contact">
          <div className='contact-container'>
            <span className='contact-title'>Request for Proposal</span>
            <span className='contact-subtitle'>We are here for all your needs on Pension Actuarial, Benefit Administration, and Insurance Consulting.</span>

            <div className='proposal-container'>
            <div className='forms'>
                {selectedDiv === null && 
                <div className='form-placeholder'>
                  <span>Please select a line of business.</span>
                </div>
                }
                {selectedDiv === 1 && 
                  <div className='each-form'>
                    {/* <h2>Form 1</h2> */}
                    <div className='prop-body'>

                    <form onSubmit={handleSubmit}>
                    <div className='two-cols'>
                      <TextField
                        className='each-input'
                        id="outlined-basic"
                        label="Name"
                        variant="outlined"
                        value={formData.addresseeName}
                        onChange={handleChange} name="addresseeName"
                        error={!!errors.addresseeName}
                        helperText={errors.addresseeName} />
                      <TextField
                        className='each-input'
                        id="outlined-basic"
                        label="Designation"
                        variant="outlined"
                        value={formData.addresseeDesignation}
                        onChange={handleChange}
                        name="addresseeDesignation"
                        error={!!errors.addresseeDesignation}
                        helperText={errors.addresseeDesignation} />
                    </div>
                    <div className='two-cols'>
                      <TextField
                        className='each-input'
                        id="outlined-basic"
                        label="Company Name"
                        variant="outlined"
                        value={formData.companyName}
                        onChange={handleChange}
                        name="companyName"
                        error={!!errors.companyName} helperText={errors.companyName} />
                      <TextField
                        className='each-input'
                        id="outlined-basic"
                        label="Company Address"
                        variant="outlined"
                        value={formData.companyAddress}
                        onChange={handleChange}
                        name="companyAddress"
                        error={!!errors.companyAddress}
                        helperText={errors.companyAddress} />
                    </div>
                    <div className='two-cols'>
                      <TextField
                        className='each-input'
                        id="outlined-basic"
                        label="Email Address"
                        variant="outlined"
                        value={formData.emailAddress}
                        onChange={handleChange}
                        name="emailAddress"
                        error={!!errors.emailAddress}
                        helperText={errors.emailAddress} />
                      <TextField
                        className='each-input'
                        id="outlined-basic"
                        label="Contact Number"
                        variant="outlined"
                        value={formData.contactNumber}
                        onChange={handleChange}
                        name="contactNumber"
                        error={!!errors.contactNumber}
                        helperText={errors.contactNumber} />
                    </div>
                    <div className='two-cols'>
                      <TextField
                        className='each-input'
                        id="outlined-basic"
                        label="Headcount"
                        variant="outlined"
                        value={formData.employeeCount}
                        onChange={handleChange}
                        name="employeeCount"
                        error={!!errors.employeeCount}
                        helperText={errors.employeeCount} />
                      <TextField className='each-input'
                        id="outlined-basic"
                        label="Calendar / Fiscal Year-End"
                        variant="outlined"
                        value={formData.fiscalYearEnd}
                        onChange={handleChange}
                        name="fiscalYearEnd"
                        error={!!errors.fiscalYearEnd}
                        helperText={errors.fiscalYearEnd} />
                    </div>
                    <div className='two-cols'>
                      <TextField
                        className='each-input'
                        id="outlined-basic" l
                        label="Last Actuarial Report Date"
                        variant="outlined"
                        value={formData.retirementPlanType}
                        onChange={handleChange}
                        name="retirementPlanType"
                        error={!!errors.retirementPlanType}
                        helperText={errors.retirementPlanType} />
                      <TextField
                        className='each-input'
                        id="outlined-basic"
                        label="Current Provider"
                        variant="outlined"
                        value={formData.actuarialProvider}
                        onChange={handleChange}
                        name="actuarialProvider"
                        error={!!errors.actuarialProvider}
                        helperText={errors.actuarialProvider} />
                    </div>

                    <div className='checkbox-group'>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.actuarialValuation}
                            onChange={handleChange}
                            name="actuarialValuation"
                            color="primary"
                          />
                        }
                        label="PAS19 Actuarial Valuation Report"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formData.fundingValuation}
                            onChange={handleChange}
                            name="fundingValuation"
                            color="primary"
                          />
                        }
                        label="Actuarial Funding Valuation"
                      />
                    </div>
                    
                    <div className='message'>
                      <TextField
                        multiline
                        rows={4}
                        className='each-input'
                        id="outlined-basic"
                        label="Message"
                        ariant="outlined"
                        value={formData.message}
                        onChange={handleChange}
                        name="message"
                        error={!!errors.message}
                        helperText={errors.message} />
                    </div>
                    <div className='alert'>
                      {success && (
                        <Alert severity="success" style={{ marginTop: '1em' }}>
                          Your message has been sent successfully!
                        </Alert>
                      )}
                    </div>

                    <div className='two-cols'>
                      <div className='blank'></div>
                      <button className='send-form' type='submit'> Submit </button>
                    </div>
                  </form>
                    </div>
                  </div>
                }
                {selectedDiv === 2 && 
                <div>
                  {/* <h2>Form 1</h2> */}
                  <div className='prop-body'>

                    <form onSubmit={handleSubmitBenefits}>
                      <div className='two-cols'>
                        <TextField
                          className='each-input'
                          id="outlined-basic"
                          label="Name"
                          variant="outlined"
                          value={formData.addresseeName}
                          onChange={handleChange} name="addresseeName"
                          error={!!errors.addresseeName}
                          helperText={errors.addresseeName} />
                        <TextField
                          className='each-input'
                          id="outlined-basic"
                          label="Designation"
                          variant="outlined"
                          value={formData.addresseeDesignation}
                          onChange={handleChange}
                          name="addresseeDesignation"
                          error={!!errors.addresseeDesignation}
                          helperText={errors.addresseeDesignation} />
                      </div>
                      <div className='two-cols'>
                        <TextField
                          className='each-input'
                          id="outlined-basic"
                          label="Company Name"
                          variant="outlined"
                          value={formData.companyName}
                          onChange={handleChange}
                          name="companyName"
                          error={!!errors.companyName} helperText={errors.companyName} />
                        <TextField
                          className='each-input'
                          id="outlined-basic"
                          label="Company Address"
                          variant="outlined"
                          value={formData.companyAddress}
                          onChange={handleChange}
                          name="companyAddress"
                          error={!!errors.companyAddress}
                          helperText={errors.companyAddress} />
                      </div>
                      <div className='two-cols'>
                        <TextField
                          className='each-input'
                          id="outlined-basic"
                          label="Headcount"
                          variant="outlined"
                          value={formData.employeeCount}
                          onChange={handleChange}
                          name="employeeCount"
                          error={!!errors.employeeCount}
                          helperText={errors.employeeCount} />
                        <TextField className='each-input'
                          id="outlined-basic"
                          label="Current DC Administrator"
                          variant="outlined"
                          value={formData.dcAdmin}
                          onChange={handleChange}
                          name="dcAdmin"
                          error={!!errors.dcAdmin}
                          helperText={errors.dcAdmin} />
                      </div>
                      <div className='two-cols'>
                        <TextField
                          className='each-input'
                          id="outlined-basic"
                          label="Email Address"
                          variant="outlined"
                          value={formData.emailAddress}
                          onChange={handleChange}
                          name="emailAddress"
                          error={!!errors.emailAddress}
                          helperText={errors.emailAddress} />
                        <TextField
                          className='each-input'
                          id="outlined-basic"
                          label="Contact Number"
                          variant="outlined"
                          value={formData.contactNumber}
                          onChange={handleChange}
                          name="contactNumber"
                          error={!!errors.contactNumber}
                          helperText={errors.contactNumber} />
                      </div>
                      <div className='two-cols'>
                        <TextField
                          className='each-input'
                          id="outlined-basic"
                          label="Online or Paper-Based"
                          variant="outlined"
                          value={formData.onlineManual}
                          onChange={handleChange}
                          name="onlineManual"
                          error={!!errors.onlineManual}
                          helperText={errors.onlineManual} />
     
                      </div>
                      <div className='message'>
                        <TextField
                          multiline
                          rows={4}
                          className='each-input'
                          id="outlined-basic"
                          label="Message"
                          ariant="outlined"
                          value={formData.message}
                          onChange={handleChange}
                          name="message"
                          error={!!errors.message}
                          helperText={errors.message} />
                      </div>
                      <div className='alert'>
                        {success && (
                          <Alert severity="success" style={{ marginTop: '1em' }}>
                            Your message has been sent successfully!
                          </Alert>
                        )}
                      </div>

                      <div className='two-cols'>
                        <div className='blank'></div>
                        <button className='send-form' type='submit'> Submit </button>
                      </div>
                    </form>
                  </div>
                </div>
                }
                {selectedDiv === 3 &&
                  <div>
                  {/* <h2>Form 2</h2> */}
                  <div className='prop-body'>

                    <form onSubmit={handleSubmitInsurance}>
                      <div className='two-cols'>
                        <TextField
                          className='each-input'
                          id="outlined-basic"
                          label="Name"
                          variant="outlined"
                          value={formData.addresseeName}
                          onChange={handleChange} name="addresseeName"
                          error={!!errors.addresseeName}
                          helperText={errors.addresseeName} />
                        <TextField
                          className='each-input'
                          id="outlined-basic"
                          label="Designation"
                          variant="outlined"
                          value={formData.addresseeDesignation}
                          onChange={handleChange}
                          name="addresseeDesignation"
                          error={!!errors.addresseeDesignation}
                          helperText={errors.addresseeDesignation} />
                      </div>
                      <div className='two-cols'>
                        <TextField
                          className='each-input'
                          id="outlined-basic"
                          label="Company Name"
                          variant="outlined"
                          value={formData.companyName}
                          onChange={handleChange}
                          name="companyName"
                          error={!!errors.companyName} helperText={errors.companyName} />
                        <TextField
                          className='each-input'
                          id="outlined-basic"
                          label="Company Address"
                          variant="outlined"
                          value={formData.companyAddress}
                          onChange={handleChange}
                          name="companyAddress"
                          error={!!errors.companyAddress}
                          helperText={errors.companyAddress} />
                      </div>
                      {/* <div className='two-cols'>
                        <TextField
                          className='each-input'
                          id="outlined-basic"
                          label="Employee Count"
                          variant="outlined"
                          value={formData.employeeCount}
                          onChange={handleChange}
                          name="employeeCount"
                          error={!!errors.employeeCount}
                          helperText={errors.employeeCount} />
                        <TextField className='each-input'
                          id="outlined-basic"
                          label="Calendar / Fiscal Year-End"
                          variant="outlined"
                          value={formData.fiscalYearEnd}
                          onChange={handleChange}
                          name="fiscalYearEnd"
                          error={!!errors.fiscalYearEnd}
                          helperText={errors.fiscalYearEnd} />
                      </div> */}
                      {/* <div className='two-cols'>
                        <TextField
                          className='each-input'
                          id="outlined-basic"
                          label="Previous Actuarial Provider"
                          variant="outlined"
                          value={formData.actuarialProvider}
                          onChange={handleChange}
                          name="actuarialProvider"
                          error={!!errors.actuarialProvider}
                          helperText={errors.actuarialProvider} />
                        <TextField
                          className='each-input'
                          id="outlined-basic" l
                          label="Type of Retirement Plan (DB, DC, None)"
                          variant="outlined"
                          value={formData.retirementPlanType}
                          onChange={handleChange}
                          name="retirementPlanType"
                          error={!!errors.retirementPlanType}
                          helperText={errors.retirementPlanType} />
                      </div> */}
                      <div className='two-cols'>
                       
                        <TextField
                          className='each-input'
                          id="outlined-basic"
                          label="Email Address"
                          variant="outlined"
                          value={formData.emailAddress}
                          onChange={handleChange}
                          name="emailAddress"
                          error={!!errors.emailAddress}
                          helperText={errors.emailAddress} />
                           <TextField
                          className='each-input'
                          id="outlined-basic"
                          label="Contact Number"
                          variant="outlined"
                          value={formData.contactNumber}
                          onChange={handleChange}
                          name="contactNumber"
                          error={!!errors.contactNumber}
                          helperText={errors.contactNumber} />
                      </div>
                      <div className='message'>
                        <TextField
                          multiline
                          rows={4}
                          className='each-input'
                          id="outlined-basic"
                          label="Message"
                          ariant="outlined"
                          value={formData.message}
                          onChange={handleChange}
                          name="message"
                          error={!!errors.message}
                          helperText={errors.message} />
                      </div>
                      <div className='alert'>
                        {success && (
                          <Alert severity="success" style={{ marginTop: '1em' }}>
                            Your message has been sent successfully!
                          </Alert>
                        )}
                      </div>

                      <div className='two-cols'>
                        <div className='blank'></div>
                        <button className='send-form' type='submit'> Submit </button>
                      </div>
                    </form>
                  </div>
                </div>
                }
              </div>
              <div className='options'>
                {/* <div className="each-options"> */}
                  {divData.map((div) => (
                    <div className='each-options'
                      key={div.id}
                      onClick={() => handleClick(div.id)}
                      style={{
                        backgroundImage: `url(${div.image})`,
                        backgroundSize: 'cover',
                        position: 'relative',
                        width: '300px',
                        // height: '150px',
                        // margin: '10px',
                        filter: selectedDiv === div.id ? 'grayscale(0%)' : 'grayscale(100%)',
                        transition: 'filter 0.3s',
                        cursor: 'pointer',
                      }}
                    >
                      <div
                        style={{
                          background: div.overlayColor,
                          opacity: selectedDiv === div.id ? 1 : 0.5,
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          bottom: 0,
                          left: 0,
                        }}
                      />
                      <div className='label'
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          color: 'white',
                          fontSize: '24px',
                          fontWeight: 'bold',
                          textAlign: 'center',
                          pointerEvents: 'none',
                        }}
                      >
                        {div.label}
                      </div>
                    </div>
                  ))}
                {/* </div> */}
              </div>
              
            </div>

            <div className='map-container'>
              <span className='contact-title'>Visit Us</span>
              <span className='contact-subtitle'>9th Floor Unit 908, The Finance Centre, 26th Street corner 9th Avenue, Bonifacio Global City, Taguig City, Metro Manila</span>
              
              <div className='actual-map-container'>
              <iframe width="100%"
                      height="100%"
                      style={{ border: 0 }}
                      loading="lazy"
                      allowFullScreen
                      src={mapSrc} >
              </iframe>
              </div>
            </div>

            <div className='contact-links'>
                <a href={`tel:+639176260572`}>
                  <div className='each-contact-link'>
                    <div className='icon-logo call'>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_210_167)">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M18.862 16.6886C18.2817 18.4521 16.0082 19.3315 14.3838 19.1849C12.1654 18.9843 9.75152 17.8077 7.91915 16.5118C5.22573 14.6067 2.70277 11.6598 1.23261 8.53277C0.193595 6.32314 -0.0395224 3.60642 1.50584 1.59232C2.07736 0.847837 2.69525 0.450226 3.62397 0.403853C4.9124 0.341186 5.09288 1.07827 5.5353 2.22633C5.86493 3.08486 6.30485 3.96063 6.5505 4.85049C7.01048 6.51116 5.40245 6.58057 5.19941 7.93793C5.07282 8.79395 6.11058 9.94193 6.57933 10.5523C7.49802 11.748 8.60221 12.7793 9.84802 13.5639C10.5612 14.0138 11.713 14.8246 12.5302 14.3772C13.7885 13.6878 13.6694 11.566 15.4291 12.2842C16.3403 12.6552 17.2226 13.1906 18.0924 13.6593C19.4373 14.3825 19.3746 15.132 18.862 16.6886C19.2455 15.5255 18.4785 17.8517 18.862 16.6886Z" fill="white" />
                        </g>
                        <defs>
                          <clipPath id="clip0_210_167">
                            <rect width="18.8" height="18.8" fill="white" transform="translate(0.400024 0.400146)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <span>+63 917 626 0572</span>
                  </div>
                </a>
                <a href="mailto:info@MAI-Asia.com">
                  <div className='each-contact-link'>
                    <div className='icon-logo email'>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_210_174)">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M9.93332 14.4758L0.533325 6.18029V16.8502H19.3333V6.18029L9.93332 14.4758ZM9.93427 11.9735L0.533325 3.6724V2.75024H19.3333V3.6724L9.93427 11.9735Z" fill="white" />
                        </g>
                        <defs>
                          <clipPath id="clip0_210_174">
                            <rect width="18.8" height="18.8" fill="white" transform="translate(0.533325 0.400146)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <span>info@MAI-Asia.com</span>
                  </div>
                </a>
                <a href="https://www.facebook.com/MiraviteConsulting/" target="_blank">
                  <div className='each-contact-link'>
                    <div className='icon-logo fb'>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_210_181)">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7786 19.2001V10.7401H14.347L14.7666 6.98015H11.7786V5.1488C11.7786 4.1806 11.8033 3.22015 13.1563 3.22015H14.5266V0.531875C14.5266 0.491455 13.3495 0.400146 12.1587 0.400146C9.67177 0.400146 8.11457 1.95791 8.11457 4.81833V6.98015H5.36658V10.7401H8.11457V19.2001H11.7786Z" fill="white" />
                        </g>
                        <defs>
                          <clipPath id="clip0_210_181">
                            <rect width="18.8" height="18.8" fill="white" transform="translate(0.666626 0.400146)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <span>Miravite Consulting Group</span>
                  </div>
                </a>
              </div>

          </div>
        </section>
      </div>
    </ThemeProvider>
  );
};

const Form1 = () => (
  <div>
    {/* <h2>Form 1</h2>
    <div className='prop-body'>

      <form onSubmit={handleSubmit}>
        <div className='two-cols'>
          <TextField
            className='each-input'
            id="outlined-basic"
            label="Proposal Addressee Name"
            variant="outlined"
            value={formData.addresseeName}
            onChange={handleChange} name="addresseeName"
            error={!!errors.addresseeName}
            helperText={errors.addresseeName} />
          <TextField
            className='each-input'
            id="outlined-basic"
            label="Proposal Addressee Designation"
            variant="outlined"
            value={formData.addresseeDesignation}
            onChange={handleChange}
            name="addresseeDesignation"
            error={!!errors.addresseeDesignation}
            helperText={errors.addresseeDesignation} />
        </div>
        <div className='two-cols'>
          <TextField
            className='each-input'
            id="outlined-basic"
            label="Company Name"
            variant="outlined"
            value={formData.companyName}
            onChange={handleChange}
            name="companyName"
            error={!!errors.companyName} helperText={errors.companyName} />
          <TextField
            className='each-input'
            id="outlined-basic"
            label="Company Address"
            variant="outlined"
            value={formData.companyAddress}
            onChange={handleChange}
            name="companyAddress"
            error={!!errors.companyAddress}
            helperText={errors.companyAddress} />
        </div>
        <div className='two-cols'>
          <TextField
            className='each-input'
            id="outlined-basic"
            label="Employee Count"
            variant="outlined"
            value={formData.employeeCount}
            onChange={handleChange}
            name="employeeCount"
            error={!!errors.employeeCount}
            helperText={errors.employeeCount} />
          <TextField className='each-input'
            id="outlined-basic"
            label="Calendar / Fiscal Year-End"
            variant="outlined"
            value={formData.fiscalYearEnd}
            onChange={handleChange}
            name="fiscalYearEnd"
            error={!!errors.fiscalYearEnd}
            helperText={errors.fiscalYearEnd} />
        </div>
        <div className='two-cols'>
          <TextField
            className='each-input'
            id="outlined-basic"
            label="Previous Actuarial Provider"
            variant="outlined"
            value={formData.actuarialProvider}
            onChange={handleChange}
            name="actuarialProvider"
            error={!!errors.actuarialProvider}
            helperText={errors.actuarialProvider} />
          <TextField
            className='each-input'
            id="outlined-basic" l
            label="Type of Retirement Plan (DB, DC, None)"
            variant="outlined"
            value={formData.retirementPlanType}
            onChange={handleChange}
            name="retirementPlanType"
            error={!!errors.retirementPlanType}
            helperText={errors.retirementPlanType} />
        </div>
        <div className='two-cols'>
          <TextField
            className='each-input'
            id="outlined-basic"
            label="Contact Number"
            variant="outlined"
            value={formData.contactNumber}
            onChange={handleChange}
            name="contactNumber"
            error={!!errors.contactNumber}
            helperText={errors.contactNumber} />
          <TextField
            className='each-input'
            id="outlined-basic"
            label="Email Address"
            variant="outlined"
            value={formData.emailAddress}
            onChange={handleChange}
            name="emailAddress"
            error={!!errors.emailAddress}
            helperText={errors.emailAddress} />
        </div>
        <div className='message'>
          <TextField
            multiline
            rows={4}
            className='each-input'
            id="outlined-basic"
            label="Message"
            ariant="outlined"
            value={formData.message}
            onChange={handleChange}
            name="message"
            error={!!errors.message}
            helperText={errors.message} />
        </div>
        <div className='alert'>
          {success && (
            <Alert severity="success" style={{ marginTop: '1em' }}>
              Your message has been sent successfully!
            </Alert>
          )}
        </div>

        <div className='two-cols'>
          <div className='blank'></div>
          <button className='send-form' type='submit'> Submit </button>
        </div>
      </form>
    </div> */}
  </div>
);

const Form2 = () => (
  <div>
    <h2>Form 2</h2>
    {/* Form 2 content goes here */}
  </div>
);

export default Contact;